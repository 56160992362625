import { render, staticRenderFns } from "./TeamMember.vue?vue&type=template&id=02f9df20&scoped=true"
import script from "./TeamMember.vue?vue&type=script&lang=js"
export * from "./TeamMember.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02f9df20",
  null
  
)

export default component.exports